.hero {
  background-repeat: no-repeat;
  background-image: url("bg.jpg");
  background-size: cover;
  background-position: center;
  box-shadow: inset 0 0 0 50vw rgba(0,0,0,.85);
}

.compensation {
  background-repeat: no-repeat;
  background-image: url("comp.jpg");
  background-size: cover;
  background-position: center;
  box-shadow: inset 0 0 0 50vw rgba(0,0,0,.85);
}

.topMenu {
  display: none;
}

.hamburger {
  background: transparent;
  z-index: 50;
  margin-right: 10px;
}

.navBar {
  position: relative;
}

.navBar button {
  position: fixed;
  left: 40px;
  top: 40px;
  z-index: 1000;
  cursor: pointer;
}

.btn {
  box-shadow: inset 0 0 0 50vw rgba(255,255,255,.20);
}

.menuNav {
  overflow-y: scroll;
  list-style: none;
  position: fixed;
  top: 0;
  box-shadow: inset 0 0 0 50vw rgba(0,0,0,.85);
  right: 0;
  bottom: 0;
  height: 100vh;
  width: 0;
  overflow: hidden;
  max-width: 290px;
  z-index: 9;
}

.menuNav.showMenu {
  width: 100%;
}

a {
  display: block;
  padding: 10px 40px;
  text-decoration: none;
  color: #fff;
  text-transform: uppercase;
  font-weight: bold;
}

.menuNav li:first-child {
  margin-top: 4rem;
}

.xray {
  background-repeat: no-repeat;
  background-image: url("xray.png");
  background-size: cover;
  background-position: center;
  box-shadow: inset 0 0 0 50vw rgba(0,0,0,0);
}

.custom-leading {
  line-height: 3rem;
}

@media only screen and (min-width: 768px) {
  /*
    enabled for everything larger than min-width
  */

  /*.xray {*/
  /*  height: calc(711px/1.5);*/
  /*  width: calc(800px/1.5);*/
  /*}*/

  .hamburger {
    display: none;
  }
  .topMenu {
    display: inline-flex;
  }
}
