@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  [type='text'],
  [type='email'],
  [type='url'],[
  type='password'],
  [type='number'],
  [type='date'],
  [type='datetime-local'],
  [type='month'],
  [type='search'],
  [type='time'],
  [type='tel'],
  [type='week'],
  [multiple],
  textarea,
  select {
    @apply w-full border-gray-300 rounded-lg shadow-sm focus:border-gray-700 focus:ring-cyan-600;
  }

  [type='checkbox'],
  [type='radio'] {
    @apply rounded border-gray-300 text-indigo-400 focus:ring-indigo-300 pb-5;
  }
}

@layer components {
  .button {
    @apply border border-slate-300 bg-transparent px-14 py-3 text-gray-100 text-2xl font-semibold;
  }

  .round-button {
    @apply border border-slate-300 rounded-full bg-transparent px-14 py-3 text-gray-100 font-semibold;
  }
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
